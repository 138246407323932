import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	season: {
		alignItems: "center",
		background: theme.palette.secondary.dark,
		borderRadius: "50%",
		display: "flex",
		marginRight: theme.spacing(1),
		height: 50,
		justifyContent: "center",
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.short
		}),
		width: 50,
		[theme.breakpoints.down("sm")]: {
			display: "none"
		},
		[theme.breakpoints.up("lg")]: {
			borderRadius: 50,
			background: theme.palette.secondary.main,
			width: "auto",
			"& a": {
				alignItems: "center",
				display: "flex",
				justifyContent: "center",
				gap: 10,
				height: "100%",
				padding: theme.spacing(0, 2),
				width: "100%",
				"& > span": {
					color: "#FFFFFF",
					fontSize: 16,
					fontWeight: 600,
					textTransform: "uppercase"
				}
			}
		},
		"&:hover": {
			background: theme.palette.secondary.dark
		},
		"& svg path": {
			fill: "#FFFFFF"
		}
	},
	seasonSticky: {
		background: theme.palette.secondary.dark
	}
}));

export default styles;

import React, { useContext, useRef } from "react";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import ReactLink from "@micado-digital/react-link/ReactLink";
import UiContext from "@UiContext";

import styles from "./MenuItem.styles";

const MenuItem = ({ hasChildren, item, level, onClick, selected }) => {
	const theme = useTheme();
	const css = styles(level, theme);
	const ref = useRef(null);
	const [ui, setUi] = useContext(UiContext);

	return (
		<div className={clsx(css.item, "mco-view-component-desktopmenu__item")}>
			<ReactLink
				className={clsx(css.link, "mco-view-component-desktopmenu__link", {
					[css.selected]: selected,
					"mco-view-component-desktopmenu__link--selected": selected
				})}
				onClick={e => {
					if (hasChildren && !selected) {
						e.preventDefault();
						onClick(item.id);
						return;
					}
					setUi({ ...ui, menuOpen: false });
				}}
				rel={item?.linkRel}
				target={item?.target}
				to={item?.link}
			>
				<span ref={ref}>{item.title}</span>
			</ReactLink>
		</div>
	);
};

export default MenuItem;

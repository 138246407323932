import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useScrollYPosition } from "react-use-scroll-position";
import useTheme from "@material-ui/core/styles/useTheme";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "../Container";
import ShopIcons from "./ShopIcons";
import Hamburger from "./Hamburger";
import Lang from "./Lang";
import Season from "./Quicklinks/Season";
import Logo from "./Logo";
import Search from "./Search";
import Quicklinks from "./Quicklinks";
import MobileMenu from "./Menu/MobileMenu";
import DesktopMenu from "./Menu/DesktopMenu";

import styles from "./Header.styles";

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [stickyHeader, setStickyHeader] = useState(false);
	const [pageData] = useContext(PageContext);
	const { fields, lang = "de", layoutVariant, layoutTag, menuGroups } = pageData;
	const [ui] = useContext(UiContext);
	const { _loading } = ui;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	const menuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Hauptmenü");
	const colorTheme = fields?.find(fields => fields?.title === "Farbe")?.value?.value;
	const theme = useTheme();
	const css = styles(colorTheme, theme);
	const scrollY = useScrollYPosition();

	useEffect(() => {
		if (!_loading) {
			setMenuOpen(false);
		}
	}, [_loading]);

	useEffect(() => {
		if (scrollY > 90) {
			setStickyHeader(true);
		} else {
			setStickyHeader(false);
		}
	}, [scrollY]);

	const Menu = () => {
		if (isDesktop) {
			return <DesktopMenu menuGroup={menuGroup} menuOpen={menuOpen} setOpen={setMenuOpen} />;
		} else {
			return <MobileMenu />;
		}
	};

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.stickyHeader]:
					stickyHeader || layoutVariant === "no-animation" || layoutTag === "ski-shop",
				[css.menuActive]: menuOpen && isMobile
			})}
		>
			<Container
				className={clsx(css.container, "mco-view-component-header__container")}
				maxWidth={false}
			>
				<Hamburger
					menuOpen={menuOpen}
					setMenuOpen={setMenuOpen}
					sticky={stickyHeader || layoutTag === "ski-shop"}
				/>
				<Season lang={lang} sticky={stickyHeader || layoutTag === "ski-shop"} />
				<Logo
					sticky={stickyHeader || layoutTag === "ski-shop"}
					layoutVariant={layoutVariant}
				/>
				{isDesktop && (
					<>
						<Search />
						<Lang />
					</>
				)}
				<ShopIcons sticky={stickyHeader || layoutTag === "ski-shop"} />
				<Quicklinks
					lang={lang}
					menuOpen={menuOpen}
					sticky={stickyHeader || layoutTag === "ski-shop"}
				/>
			</Container>
			{menuOpen && Menu()}
		</div>
	);
};

export default Header;

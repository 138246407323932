import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Container from "@Container";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import Level1 from "./Level1";

import styles from "./DesktopMenu.styles";

const DesktopMenu = ({ menuGroup, menuOpen, setOpen }) => {
	const css = styles();
	const [selectedMenuLevel1ID, setSelectedMenuLevel1ID] = useState();
	const [submenuIsActive, setSubmenuIsActive] = useState(false);
	const menuLevel2Ref = useRef();

	const handleClose = () => {
		setOpen(false);
	};

	const handleLevel1Click = level1ID => {
		setSubmenuIsActive(!submenuIsActive);
		if (level1ID === selectedMenuLevel1ID) {
			setSelectedMenuLevel1ID(null);
			return;
		}

		setSelectedMenuLevel1ID(level1ID);
	};

	useEffect(() => {
		const selectedMainPoint = menuGroup?.items?.find(item => item.selected);
		setSelectedMenuLevel1ID(selectedMainPoint?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Drawer
			anchor="left"
			classes={{ paper: css.root }}
			disableScrollLock={true}
			open={menuOpen}
			onClose={() => handleClose()}
		>
			<Container
				className={clsx(css.desktopmenu, "mco-view-component-desktopmenu", {
					[css.submenuActive]: submenuIsActive
				})}
				maxWidth={false}
			>
				<div className={css.close} onClick={() => handleClose()}>
					<ReactSVGIcon src="/img/icons/close.svg" size={24} />
				</div>
				<Level1
					handleLevel1Click={handleLevel1Click}
					items={menuGroup?.items}
					menuLevel2Ref={menuLevel2Ref}
					selectedMenuLevel1ID={selectedMenuLevel1ID}
				></Level1>
			</Container>
		</Drawer>
	);
};

export default DesktopMenu;

import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	mobilemenu: {
		background: theme.palette.background.menu,
		bottom: 0,
		left: 0,
		width: "100%",
		zIndex: 1000,
		position: "fixed",
		padding: theme.spacing(8, 2, 3),
		overflowX: "hidden",
		overflowY: "auto",
		top: 90
	},
	container: {
		height: "100%",
		position: "relative",
		zIndex: 100
	}
}));

export default styles;

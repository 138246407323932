import React, { useContext } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useSystemLinksExt from "@micado-digital/react-ui/hooks/useSystemLinksExt";

import styles from "./Season.styles";

const Season = ({ sticky }) => {
	const [ui, setUi] = useContext(UiContext);
	const screenLG = useMediaQuery(theme => theme.breakpoints.up("lg"));
	const sysLink = useSystemLinksExt();
	const css = styles();
	const buttonLabel = sysLink("season-button")?.title;

	if (!sysLink("season-button")?.url) {
		return <></>;
	}

	return (
		<div
			className={clsx(css.season, "mco-view-component-header__quicklinks-season", {
				[css.seasonSticky]: sticky
			})}
		>
			<ReactLink
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
				to={sysLink("season-button")?.url}
			>
				<ReactSVGIcon
					className={css.icon}
					size={24}
					src={buttonLabel === "Winter" ? "/img/icons/snow.svg" : "/img/icons/sun.svg"}
				/>
				{screenLG && <span>{buttonLabel}</span>}
			</ReactLink>
		</div>
	);
};

export default Season;

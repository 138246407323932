import makeStyles from "@material-ui/styles/makeStyles";

const styles = (level, theme) => {
	let linkStyles = null;
	let itemStyles = null;

	switch (level) {
		case 1:
		default:
			itemStyles = {
				marginTop: theme.spacing(3)
			};
			linkStyles = {
				...theme?.customTypography?.menu?.desktop?.level1
			};
			break;
		case 2:
			itemStyles = {
				marginTop: theme.spacing(2)
			};
			linkStyles = {
				...theme?.customTypography?.menu?.desktop?.level2
			};
			break;
	}

	return makeStyles(theme => ({
		item: {
			...itemStyles
		},
		link: {
			color: "#FFFFFF",
			position: "relative",
			...linkStyles
		},
		selected: {
			color: theme.palette.primary.main,
			opacity: 1
		}
	}))();
};

export default styles;

import React, { useContext } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Acco.styles";

const Acco = ({ lang, sticky }) => {
	const [ui, setUi] = useContext(UiContext);
	const screenLG = useMediaQuery(theme => theme.breakpoints.up("lg"));
	const sysLink = useSystemLinks();
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<div
			className={clsx(css.acco, "mco-view-component-header__quicklinks-acco", {
				[css.accoSticky]: sticky
			})}
		>
			<a
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
				href={sysLink("acco")}
				target="_blank"
				rel="noreferrer"
			>
				<ReactSVGIcon className={css.icon} size={24} src="/img/icons/bed.svg" />
				{screenLG && <span>{l("header.acco")}</span>}
			</a>
		</div>
	);
};

export default Acco;

import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import getJSXElement from "./getJSXElement";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Elements.styles";

const Elements = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	let { elements, fields, lang = "de" } = pageData;
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const getSeason = () => {
		if (fields && fields?.length > 0) {
			return fields
				?.filter(item => item?.tag === "season")
				?.map(item => item?.value?.value)
				.join()
				.toLowerCase();
		} else {
			return "winter";
		}
	};

	// group downloads

	elements = groupFollowingElementsByTag(elements, "basic-download-group", "basic-download");

	// group dropdowns

	elements = groupFollowingElementsByTag(
		elements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	return (
		<div className={clsx(css.elements, "mco-elements")} style={{ overflow: "hidden" }}>
			{elements?.map(element => {
				return getJSXElement(element, l, lang, sysLink, isMobile, getSeason());
			})}
		</div>
	);
};

export default Elements;

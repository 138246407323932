import React, { useContext } from "react";
import PageContext from "@PageContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import ReactWeather02 from "@micado-digital/react-weather/ReactWeather02";
import ReactLink from "@micado-digital/react-link/ReactLink";
import Local from "@Local";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Weather.styles";

const Weather = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang = "de" } = pageData;
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<div className={css.weather}>
			<div className={css.topWrapper}>
				<Typography className={css.headline} variant="h4" component="p">
					<Local identifier="overlay.headlineWeather" l={l} />
				</Typography>
				<ReactLink className={css.button} to={sysLink("weather")}>
					<Button
						color="primary"
						variant="text"
						endIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} />}
					>
						{l("overlay.button")}
					</Button>
				</ReactLink>
			</div>
			<ReactWeather02
				lang={lang}
				station={3}
				url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api`}
			/>
			<a
				href="https://www.geosphere.at/"
				target="_blank"
				rel="noreferrer"
				style={{
					color: "#FFFFFF",
					display: "table",
					fontSize: 14,
					fontWeight: "normal",
					marginTop: 16
				}}
			>
				&copy; GeoSphere Austria
			</a>
		</div>
	);
};

export default Weather;

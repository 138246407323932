import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		alignItems: "center",
		background: theme.palette.secondary.light,
		borderRadius: "50%",
		cursor: "pointer",
		display: "flex",
		height: 50,
		justifyContent: "center",
		width: 50,
		[theme.breakpoints.up("md")]: {
			marginRight: theme.spacing(1)
		},
		"&.mco-view-component-header-menu-mobilemenu__hamburger--fullscreen": {
			background: theme.palette.secondary.main
		},
		"&.mco-view-component-header-menu-mobilemenu__hamburger--sticky": {
			background: theme.palette.secondary.light
		}
	},
	hamburgerClose: {
		background: theme.palette.primary.main
	}
}));

export default styles;

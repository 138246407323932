import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import SkiShopData from "@micado-digital/react-ski-shop/components/SkiShopData";
import useIsCheckout from "@micado-digital/react-ski-shop/hooks/useIsCheckout";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import useScrollToLocation from "../hooks/useScrollToLocation";
import Locals from "@micado-digital/react-ui/components/Locals";
import Animation from "@Components/views/Animation";
import Header from "@Components/views/Header";
import Default from "./Default";
import Core from "./Core";

import styles from "./Views.styles";

const Footer = loadable(() => import("@Components/views/Footer"));
const SkiShop = loadable(() => import("./SkiShop"));

const Views = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { authorID, fields, id, lang, layoutTag, layoutVariant } = pageData;
	const currentLocation = useIsCurrentLocation();
	const skiShopURLPath = "ski-shop";
	const skiShopIsCheckout = useIsCheckout({ path: skiShopURLPath });
	const css = styles();

	const getSeason = () => {
		if (fields && fields?.length > 0) {
			return fields
				?.filter(item => item?.tag === "season")
				?.map(item => item?.value?.value)
				.join()
				.toLowerCase();
		} else {
			return "winter";
		}
	};

	const hasAnimation =
		currentLocation && layoutVariant !== "no-animation" && layoutTag !== "ski-shop";
	const hasFooter = currentLocation && !skiShopIsCheckout;
	const hasHeader = !skiShopIsCheckout;

	useScrollToLocation(120);

	return (
		<WebProfile key={lang}>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<SkiShopData
						creditcards={["mastercard", "visa", "dinersclub", "discover"]}
						eTicketNoAppAlerts
						lang={lang}
						oAuth
						urlPath={skiShopURLPath}
					>
						<div className={clsx(css.page, "mco-page", `mco-page-${layoutTag}`)}>
							{hasHeader && <Header />}
							{hasAnimation && <Animation season={getSeason()} />}
							{layoutTag !== "ski-shop" && (
								<PageTransition color="rgba(0, 0, 0, 0.5)" position="top" />
							)}
							{layoutTag === "standard" && <Default lang={lang} />}
							{layoutTag === "ski-shop" && currentLocation && <SkiShop lang={lang} />}
							{hasFooter && <Footer lang={lang} season={getSeason()} />}
							<micado-ial language={lang} path={REACT_APP_PATH} page-id={id}></micado-ial>
						</div>
						<Core />
					</SkiShopData>
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;

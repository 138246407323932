import React from "react";
import clsx from "clsx";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./MenuItem.styles";

const MenuItem = ({ childrenVisible, item, level, onClick }) => {
	const { link, linkRel, selected, target, title } = item;
	const css = styles();

	return (
		<ReactLink
			className={clsx(css.menuItem, "mco-view-component-header-menu-mobilemenu-menuitem", {
				[css.level1]: level === 1,
				"mco-view-component-header-menu-mobilemenu-menuitem__level1": level === 1,
				[css.level1Selected]: level === 1 && selected,
				"mco-view-component-header-menu-mobilemenu-menuitem__level1--selected":
					level === 1 && selected,
				[css.level2]: level === 2,
				"mco-view-component-header-menu-mobilemenu-menuitem__level2": level === 2,
				[css.level2Selected]: level === 2 && selected,
				"mco-view-component-header-menu-mobilemenu-menuitem__level2--selected":
					level === 2 && selected,
				"mco-view-component-header-menu-mobilemenu-menuitem__childrenVisible": childrenVisible
			})}
			onClick={e => {
				onClick && onClick(e, item);
			}}
			rel={linkRel}
			target={target}
			to={link}
		>
			{title}
		</ReactLink>
	);
};

export default MenuItem;

import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		height: "100%",
		overflow: "auto",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		"& > div:last-child a": {
			borderBottom: 0
		},
		"& .mco-view-component-header-menu-mobilemenu-level1--active": {
			paddingBottom: theme.spacing(2)
		}
	},
	submenuActive: {
		"& > div": {
			opacity: 0.3
		},
		"& .mco-view-component-header-menu-mobilemenu-level1--active": {
			opacity: 1,
			"& > a": {
				color: theme.palette.primary.main
			}
		}
	}
}));

export default styles;

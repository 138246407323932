function duplicateParentAsFirstChild(items, label) {
	traverse(items, addToArray);

	function addToArray(key, obj) {
		if (key !== "hasDirectLink") return;

		const { hasDirectLink, items } = obj;

		if (!hasDirectLink) return;
		if (!items) return;
		if (!items.length > 0) return;
		if (items[0].id === obj.id + "_copy") return;
		if (label === "") return;

		const copy = JSON.parse(JSON.stringify(obj));
		copy.id += "_copy";
		copy.title += ` (${label})`;
		copy.selected = copy.link === window.location.pathname;
		delete copy.hasDirectLink;
		delete copy.items;

		items.unshift(copy);
	}

	function traverse(o, func) {
		for (const i in o) {
			func.apply(this, [i, o]);
			if (o[i] !== null && typeof o[i] === "object") {
				//going one step down in the object tree!!
				traverse(o[i], func);
			}
		}
	}
}

export default duplicateParentAsFirstChild;

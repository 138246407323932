const animationContainer1 = {
	animate: {
		transition: {
			staggerChildren: 0.1
		}
	}
};

const animationItem1 = {
	initial: {
		opacity: 0,
		x: -25
	},
	animate: {
		opacity: 1,
		x: 0
	}
};

export { animationContainer1, animationItem1 };

import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	"@keyframes increase": {
		"100%": {
			height: 1000
		}
	},
	animation: {
		height: "50vh",
		minHeight: 300,
		position: "relative",
		"@media (min-width: 960px) and (max-height: 800px)": {
			minHeight: 450
		},
		"@media (min-width: 1280px) and (max-height: 800px)": {
			minHeight: 512
		},
		"& .mco-animation-01__slider:before": {
			background: "rgba(0, 58, 86, 0.2)",
			content: "''",
			height: "100%",
			left: 0,
			position: "absolute",
			top: 0,
			width: "100%",
			zIndex: 2
		},
		"& .mco-animation-01__texts": {
			display: "flex",
			flexDirection: "column",
			margin: "0 auto",
			maxWidth: 1280,
			opacity: 0,
			padding: theme.spacing(2, 2, 2, 8),
			transform: "translate(-10%, 0)",
			transition: "opacity 1500ms ease, transform 1000ms ease",
			width: "100%",
			"& .mco-animation-01__text": {
				textShadow: "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.12)"
			},
			"& .mco-animation-01__text--1": {
				color: "#FFFFFF",
				...theme.typography.h1,
				lineHeight: 1.125,
				position: "relative",
				"& strong": {
					color: theme.palette.primary.main,
					fontWeight: 900
				},
				"&::before": {
					background: theme.palette.primary.main,
					borderRadius: "50%",
					content: "''",
					height: 16,
					left: -32,
					position: "absolute",
					top: 8,
					width: 16,
					[theme.breakpoints.up("md")]: {
						top: 16
					}
				},
				"&::after": {
					background: theme.palette.primary.main,
					content: "''",
					height: 1,
					left: -24.5,
					position: "absolute",
					top: 16,
					width: 2,
					animation: "$increase 2s ease 2s",
					animationFillMode: "forwards"
				}
			},
			"& .mco-animation-01__text--2": {
				color: "#FFFFFF",
				display: "none",
				...theme.typography.h6
			}
		},
		"&.mco-view-component-animation--text-visible": {
			"& .mco-animation-01__texts": {
				opacity: 1,
				transform: "translate(0, 0)"
			}
		},
		"& .mco-animation-01__bullets": {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "flex"
			},
			[theme.breakpoints.up("lg")]: {
				bottom: theme.spacing(10),
				justifyContent: "flex-start",
				left: theme.spacing(17)
			},
			"@media (min-width: 960px) and (max-height: 700px)": {
				bottom: theme.spacing(10)
			},
			"& .mco-animation-01__bullet": {
				background: theme.palette.primary.main,
				opacity: 0.5,
				height: 8,
				width: 8,
				[theme.breakpoints.up("md")]: {
					background: "#FFFFFF"
				}
			},
			"& .mco-animation-01__bullet--selected": {
				opacity: 1,
				width: 16
			}
		}
	},
	fullscreen: {
		height: "calc((var(--vh, 1vh) * 100) - 90px)",
		[theme.breakpoints.up("md")]: {
			height: "calc(var(--vh, 1vh) * 100)",
			display: "flex",
			flexDirection: "column",
			maxHeight: "unset",
			"& .mco-animation-01__slide-item": {
				zIndex: "unset"
			},
			"& .mco-animation-01__bullets": {
				[theme.breakpoints.up("lg")]: {
					bottom: theme.spacing(19.5)
				},
				"@media (min-width: 960px) and (max-height: 700px)": {
					bottom: theme.spacing(10)
				}
			}
		}
	},
	headerVisible: {
		[theme.breakpoints.up("md")]: {
			marginTop: -90
		}
	},
	overlayVisible: {
		"& .mco-quicklinks": {
			background: "linear-gradient(0deg, #003A56 53.33%, rgba(0, 58, 86, 0) 100%)",
			bottom: 0,
			position: "fixed",
			zIndex: 9000,
			"& > div": {
				background: theme.palette.secondary.light,
				opacity: 0.5,
				transition: theme.transitions.create(["opacity"], {
					duration: theme.transitions.duration.short
				}),
				"&:hover": {
					opacity: 1
				}
			},
			"& .mco-quicklinks__item--selected": {
				background: theme.palette.primary.main,
				opacity: 1
			}
		}
	}
}));

export default styles;

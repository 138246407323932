import React, { useContext } from "react";
import PageContext from "@PageContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import ReactLiftState01 from "@micado-digital/react-liftstate/ReactLiftState01";
import ReactFacilities01 from "@micado-digital/react-facilities/ReactFacilities01";
import ReactLink from "@micado-digital/react-link/ReactLink";
import Local from "@Local";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Liftstate.styles";

const Liftstate = ({ season }) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang = "de" } = pageData;
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<>
			<div className={css.liftstate}>
				<div className={css.topWrapper}>
					<Typography className={css.headline} variant="h4" component="p">
						<Local identifier="overlay.headlineLiftstate" l={l} />
					</Typography>
					<ReactLink
						className={css.button}
						to={season === "summer" ? sysLink("liftstate-summer") : sysLink("liftstate")}
					>
						<Button
							color="primary"
							variant="text"
							endIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} />}
						>
							{l("overlay.button")}
						</Button>
					</ReactLink>
				</div>
				<ReactLiftState01
					client="https://sgm.bergbahnen-stjohann.at"
					filter={false}
					lang={lang}
					location="stjohann"
					region="stjohann"
					season={season ? season : "winter"}
					skeletonItems={20}
					stateType="icon"
					url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
				/>
			</div>
			{season === "summer" && (
				<div className={css.summerActivities}>
					<div className={css.topWrapper}>
						<Typography className={css.headline} variant="h4" component="p">
							<Local identifier="overlay.headlineSummerActivities" l={l} />
						</Typography>
					</div>
					<ReactFacilities01
						client="https://sgm.bergbahnen-stjohann.at"
						filter={false}
						lang={lang}
						region="stjohann"
						showIcon={false}
						skeletonItems={3}
						stateType="icon"
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
						selected="114|116|119"
					/>
				</div>
			)}
		</>
	);
};

export default Liftstate;

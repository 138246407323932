import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		padding: theme.spacing(15, 0, 5),
		"& > div": {
			paddingLeft: theme.spacing(5),
			position: "relative",
			"&::before": {
				background: theme.palette.primary.main,
				borderRadius: "50%",
				content: "''",
				height: 16,
				left: 0,
				position: "absolute",
				top: 8,
				width: 16
			},
			"&::after": {
				background: theme.palette.primary.main,
				content: "''",
				height: 2,
				left: -400,
				position: "absolute",
				top: 15,
				width: 400
			}
		}
	},
	hideOnDesktop: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	}
}));

export default styles;

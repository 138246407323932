import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	piststate: {
		paddingTop: theme.spacing(9),
		paddingLeft: theme.spacing(4.5),
		[theme.breakpoints.up("md")]: {
			paddingLeft: 0
		},
		"& .mco-piststate": {
			marginLeft: -32,
			[theme.breakpoints.up("md")]: {
				marginLeft: 0
			},
			"& > div": {
				display: "flex",
				flexDirection: "column-reverse"
			}
		},
		"& .mco-piststate__table-row th": {
			background: "none"
		},
		"& .mco-piststate__table-cell": {
			borderBottom: "1px solid " + theme.palette.secondary.light,
			paddingBottom: 12,
			paddingTop: 12,
			"& p": {
				color: "#FFFFFF"
			},
			"& h6": {
				color: theme.palette.secondary.light
			}
		},
		"& .mco-piststate__table-cell--title": {
			[theme.breakpoints.up("md")]: {
				minWidth: 120
			}
		},
		"& .mco-piststate__table-cell--length": {
			[theme.breakpoints.up("md")]: {
				minWidth: 100
			}
		},
		"& .mco-piststate__table-cell--openinghours": {
			display: "none"
		},
		"& .mco-piststate__table-cell--height": {
			display: "none"
		},
		"& .mco-piststate__bottomWrapper": {
			margin: theme.spacing(0, 0, 2),
			paddingLeft: 32,
			[theme.breakpoints.up("md")]: {
				paddingLeft: 0
			},
			"& span": {
				color: theme.palette.secondary.light
			}
		}
	},
	skitour: {
		marginBottom: theme.spacing(1),
		paddingTop: theme.spacing(9),
		paddingLeft: theme.spacing(4.5),
		[theme.breakpoints.up("md")]: {
			paddingLeft: 0
		},
		"& .mco-facilities": {
			marginLeft: -32,
			[theme.breakpoints.up("md")]: {
				marginLeft: 0
			},
			"& > div": {
				display: "flex",
				flexDirection: "column-reverse"
			}
		},
		"& .mco-facilities__table-row th": {
			background: "none"
		},
		"& .mco-facilities__table-cell": {
			borderBottom: "1px solid " + theme.palette.secondary.light,
			paddingBottom: 12,
			paddingTop: 12,
			"& p": {
				color: "#FFFFFF"
			},
			"& div": {
				color: theme.palette.secondary.light
			}
		},
		"& td.mco-facilities__table-cell": {
			"& div": {
				color: "#FFFFFF"
			}
		},
		"& .mco-facilities__table-cell--title": {
			[theme.breakpoints.up("md")]: {
				minWidth: 120
			}
		},
		"& .mco-facilities__table-cell--length": {
			[theme.breakpoints.up("md")]: {
				minWidth: 100
			}
		},
		"& .mco-facilities__table-cell--openinghours": {
			display: "none"
		},
		"& .mco-facilities__table-cell--height, .mco-facilities__table-cell--length": {
			display: "none"
		},
		"& .mco-facilities__bottomWrapper": {
			margin: theme.spacing(0, 0, 2),
			paddingLeft: 32,
			[theme.breakpoints.up("md")]: {
				paddingLeft: 0
			},
			"& div": {
				color: theme.palette.secondary.light
			}
		}
	},
	topWrapper: {
		[theme.breakpoints.up("md")]: {
			alignItems: "center",
			display: "flex",
			gap: theme.spacing(4),
			justifyContent: "space-between",
			marginBottom: theme.spacing(4)
		}
	},
	headline: {
		color: "#FFFFFF",
		position: "relative",
		"&::before": {
			background: theme.palette.primary.main,
			borderRadius: "50%",
			content: "''",
			height: 16,
			left: -32,
			position: "absolute",
			top: 8,
			width: 16
		},
		"&::after": {
			background: theme.palette.primary.main,
			content: "''",
			height: 2,
			left: -420,
			position: "absolute",
			top: 15,
			width: 400
		}
	},
	button: {
		display: "table",
		marginTop: theme.spacing(1),
		[theme.breakpoints.up("md")]: {
			marginTop: 0
		},
		"& button": {
			fontSize: "14px !important",
			padding: "0 !important",
			transition: theme.transitions.create(["color"], {
				duration: theme.transitions.duration.short
			}),
			"&:hover": {
				background: "none",
				color: theme.palette.primary.dark
			}
		}
	}
}));

export default styles;

import React, { useContext } from "react";
import loadable from "@loadable/component";
import Elements from "@Components/Elements";
import SkiShopContext from "@micado-digital/react-ski-shop/context/SkiShopContext";

const Cart01 = loadable(() => import("@micado-digital/react-ski-shop/views/Cart01"));

const Default = ({ lang }) => {
	const { skiShopData, setSkiShopData } = useContext(SkiShopContext);
	const { showCart } = skiShopData || {};

	const handleCartClose = () => {
		setSkiShopData(prevState => ({
			...prevState,
			showCart: false
		}));
	};

	return (
		<>
			<Elements />
			{showCart && <Cart01 lang={lang} onClose={handleCartClose} />}
		</>
	);
};

export default Default;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import SkiShopContext from "@micado-digital/react-ski-shop/context/SkiShopContext";
import SkiShopURLContext from "@micado-digital/react-ski-shop/context/SkiShopURLContext";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./ShopIcons.styles";

const ShopIcons = ({ sticky }) => {
	const css = styles();
	const { skiShopData, setSkiShopData } = useContext(SkiShopContext);
	const { cart: { items = [] } = {} } = skiShopData || {};
	const { skiShopURLs } = useContext(SkiShopURLContext);
	const { urls: { dashboard: dashboardURL } = {} } = skiShopURLs || {};
	const cartItems = items.length;
	const navigate = useNavigate();

	const handleOpenCart = () => {
		setSkiShopData(prevState => ({
			...prevState,
			showCart: true
		}));
	};

	const handleGoToDashboard = () => {
		navigate(dashboardURL);
	};

	return (
		<>
			<div
				className={clsx(css.icons, "mco-view-component-header-shop-icons", {
					[css.iconsSticky]: sticky
				})}
			>
				<div
					className={clsx(css.user, "mco-view-component-header-icons-shop__user")}
					onClick={handleGoToDashboard}
				>
					<ReactSVGIcon src="/img/icons/user.svg" size={24} />
				</div>
				<div
					className={clsx(css.cart, "mco-view-component-header-icons-shop__cart")}
					onClick={handleOpenCart}
				>
					<ReactSVGIcon src="/img/icons/cart.svg" size={24} />
					{cartItems > 0 && (
						<span
							className={clsx(
								css.cartItems,
								"mco-view-component-header-icons-shop__cart-items"
							)}
						>
							{cartItems}
						</span>
					)}
				</div>
			</div>
		</>
	);
};

export default ShopIcons;

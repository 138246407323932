import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	quicklinks: {
		display: "flex",
		gap: theme.spacing(1),
		"& .mco-view-component-header-lang, .mco-view-component-header-search": {
			alignItems: "center",
			background: theme.palette.secondary.light,
			borderRadius: "50%",
			color: "#FFFFFF",
			display: "flex",
			height: 50,
			justifyContent: "center",
			width: 50
		}
	}
}));

export default styles;

import React, { useContext } from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PageContext from "@PageContext";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Hamburger.styles";

const Hamburger = ({ menuOpen, setMenuOpen, sticky }) => {
	const [pageData] = useContext(PageContext);
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = styles();

	return (
		<div
			className={clsx(css.hamburger, "mco-view-component-header-menu-mobilemenu__hamburger", {
				[css.hamburgerClose]: menuOpen,
				"mco-view-component-header-menu-mobilemenu__hamburger--fullscreen":
					pageData?.layoutVariant !== "no-animation" && screenMD,
				"mco-view-component-header-menu-mobilemenu__hamburger--sticky": sticky && screenMD
			})}
			onClick={() => setMenuOpen(!menuOpen)}
		>
			{menuOpen && <ReactSVGIcon src="/img/icons/close.svg" size={24} />}
			{!menuOpen && <ReactSVGIcon src="/img/icons/menu.svg" size={24} />}
		</div>
	);
};

export default Hamburger;

import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import MenuItem from "../MenuItem";
import Level2 from "../Level2";
import { animationContainer1, animationItem1 } from "../utils/animationLevel1";

import styles from "./Level1.styles";

const Level1 = ({ handleLevel1Click, items, menuLevel2Ref, selectedMenuLevel1ID }) => {
	const css = styles();

	return (
		<motion.div
			animate="animate"
			className={clsx(css.level1, "mco-view-component-desktopmenu__level1")}
			initial="initial"
			variants={animationContainer1}
		>
			{items?.map(item => (
				<motion.div
					className={clsx({
						"mco-view-component-desktopmenu__level1-item--active":
							item.id === selectedMenuLevel1ID
					})}
					data-id={item.id}
					key={item.id}
					variants={animationItem1}
				>
					<MenuItem
						connectorRef={menuLevel2Ref}
						hasChildren={item.items?.length > 0}
						item={item}
						level={1}
						onClick={handleLevel1Click}
						selected={item.id === selectedMenuLevel1ID}
					/>
					{item.id === selectedMenuLevel1ID && (
						<Level2 items={item?.items} selectedMenuLevel1ID={selectedMenuLevel1ID} />
					)}
				</motion.div>
			))}
		</motion.div>
	);
};

export default Level1;

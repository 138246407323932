import makeStyles from "@material-ui/styles/makeStyles";

const styles = theme => {
	return makeStyles(theme => ({
		header: {
			background: theme.palette.background.header,
			left: 0,
			position: "sticky",
			right: 0,
			top: 0,
			zIndex: 100,
			[theme.breakpoints.up("md")]: {
				background: "none"
			}
		},
		stickyHeader: {
			background: theme.palette.background.header,
			"& .mco-view-component-header__container": {
				height: 90
			}
		},
		menuActive: {
			background: theme.palette.background.menu
		},
		container: {
			alignItems: "center",
			display: "flex",
			gap: 8,
			height: 90,
			transition: "height 300ms ease",
			padding: theme.spacing(0, 2),
			[theme.breakpoints.up("lg")]: {
				padding: theme.spacing(0, 5)
			},
			"& .mco-view-component-header__lang": {
				display: "none",
				[theme.breakpoints.up("md")]: {
					display: "block",
					marginRight: "auto"
				}
			},
			[theme.breakpoints.down(600)]: {
				"& .mco-view-component-header__quicklinks-acco": {
					display: "none"
				}
			}
		}
	}))();
};

export default styles;
